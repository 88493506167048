//---activitiesData.js
import HackathonAm from "../assets/images/activities/ambank.jpg";
import TalentMax from "../assets/images/activities/talentmax1.jpg";
import TalentMaxWinner from "../assets/images/activities/talentmaxwinner.jpg";
import CnyPBB from "../assets/images/activities/cnypbb.jpg";
import Hadiah from "../assets/images/activities/hadiah.png";
import Convo from "../assets/images/activities/convo.jpg";
import PBB3 from "../assets/images/activities/annualdinner.jpg";
import Banjir from "../assets/images/activities/banjir.jpg";
import Classmate from "../assets/images/activities/classmate.jpg";
import eSportSeries from "../assets/images/activities/esportseries.jpg";
import Falcon from "../assets/images/activities/falconamenity.jpg";
import FYP from "../assets/images/activities/fyp.jpeg";
import Groupmate from "../assets/images/activities/groupmate.jpg";
import ImecAGM from "../assets/images/activities/imecagm.jpg";
import ImecFirst from "../assets/images/activities/imecfirst.jpg";
import Imec from "../assets/images/activities/imecmeeting.jpg";
import LastExam from "../assets/images/activities/lastexam.jpg";
import LastPresentation from "../assets/images/activities/lastpresentation.jpg";
import MajlisPenyerahan from "../assets/images/activities/majlispenyerahan.jpeg";
import MajlisPertama from "../assets/images/activities/majlispertama.jpg";
import Mep from "../assets/images/activities/mep.jpg";
import MMM from "../assets/images/activities/mmm.jpg";
import PBB from "../assets/images/activities/pbb.jpg";
import PBB2 from "../assets/images/activities/pbb2.jpg";
import PendetaStudio from "../assets/images/activities/pendetastudio.jpg";
import PestaBuku from "../assets/images/activities/pestabuku.jpg";
import PKMK from "../assets/images/activities/pkmk.jpg";
import PulauPerhentian from "../assets/images/activities/pulauperhentian.jpg";
import SAMPENG from "../assets/images/activities/sampeng.jpg";
import Takbir from "../assets/images/activities/takbirraya.jpg";
import UKM from "../assets/images/activities/ukm.jpg";
import Vaccine from "../assets/images/activities/vaccine.jpg";
import Workshop from "../assets/images/activities/workshop.jpg";
import ZMG from "../assets/images/activities/zmg.jpg";
import KasihBudi from "../assets/images/activities/kasihbudi.jpg";
import CyberNight from "../assets/images/activities/cybernight.jpg";
//---portfolioData.js
import AI from "../assets/images/portfolio/ai.png";
import TrackingApp from "../assets/images/portfolio/busapp.png";
import CoffeeApp from "../assets/images/portfolio/coffeeapp.png";
import Downloader from "../assets/images/portfolio/downloader.jpg";
import MyPB from "../assets/images/portfolio/mypb.jpg";
import FoodOrdering from "../assets/images/portfolio/orderingfood.jpg";
import PBeXperience from "../assets/images/portfolio/pbex.png";
import eCommerceWeb from "../assets/images/portfolio/plaza.png";
import Analysis from "../assets/images/portfolio/rapid.png";
import Recipe from "../assets/images/portfolio/recipe.png";
import eCommerceApp from "../assets/images/portfolio/ukmall.jpg";
import Website from "../assets/images/portfolio/website.png";
import MyPBLaos from "../assets/images/portfolio/mypbll.jpg";
import PBJourney from "../assets/images/portfolio/pbjourney.png";

//---designData.js
import Banking from "../assets/images/uiux/banking.jpg";
import MobileEcommerce from "../assets/images/uiux/ecommerce.jpg";
import WebsiteEcommerce from "../assets/images/uiux/ecommerce2.jpg";
import Poster from "../assets/images/uiux/poster.jpg";
import Cooking from "../assets/images/uiux/recipesharing.jpg";
//---awardsData.js
import DeanPics from "../assets/images/temp/DeanList.jpg";
import Academic from "../assets/images/temp/academic.jpg";
import Hackathon from "../assets/images/temp/hackathon.jpg";
import CocoPics from "../assets/images/temp/imec.jpg";
import MepPics from "../assets/images/temp/mep.jpg";
import Competition from "../assets/images/temp/pertandingan.png";
//---hobbiesData.js
import Badminton from "../assets/images/sports/badminton.jpg";
import Bowling from "../assets/images/sports/bowling.png";
import eSport from "../assets/images/sports/esport.jpeg";
import FitTouch from "../assets/images/sports/fittouch.jpg";
import Hiking from "../assets/images/sports/hiking.jpg";
import Snooker from "../assets/images/sports/snooker.jpg";
import Snorkeling from "../assets/images/sports/snorkeling.jpg";
import Squash from "../assets/images/sports/squash.jpg";
//---skillsData.js
import * as bootstrap from "@styled-icons/bootstrap";
import * as boxiconsLogos from "@styled-icons/boxicons-logos";
import * as simpleIcons from "@styled-icons/simple-icons";
//---certsData.js
import M0 from "../assets/images/certs/m0.jpg";
import M1 from "../assets/images/certs/m1.jpg";
import M2 from "../assets/images/certs/m2.jpg";
import M3 from "../assets/images/certs/m3.jpg";
import M4 from "../assets/images/certs/m4.jpg";
import M5 from "../assets/images/certs/m5.jpg";
import M6 from "../assets/images/certs/m6.jpg";
import M7 from "../assets/images/certs/m7.jpg";
import M8 from "../assets/images/certs/m8.jpg";
import M9 from "../assets/images/certs/m9.jpg";

import I1 from "../assets/images/certs/i1.jpg";
import I2 from "../assets/images/certs/i2.jpg";

import G0 from "../assets/images/certs/g0.jpg";
import G1 from "../assets/images/certs/g1.jpg";
import G2 from "../assets/images/certs/g2.jpg";
import G3 from "../assets/images/certs/g3.jpg";
import G4 from "../assets/images/certs/g4.jpg";

import A1 from "../assets/images/certs/a1.jpg";

import B1 from "../assets/images/certs/b1.jpg";
import B2 from "../assets/images/certs/b2.jpg";
import B3 from "../assets/images/certs/b3.jpg";
import B4 from "../assets/images/certs/b4.jpg";

//---servicesData.js
import {
  faCamera,
  faImage,
  faLaptop,
  faMobilePhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const infoData = [
  {
    label: "Name",
    value: "Adam Adha Bin Kamarul Zaman",
    description: "My nickname is Adam Kz",
  },
  {
    label: "Nationality",
    value: "Malaysian",
    description: "Citizen of Malaysia",
  },
  {
    label: "Age",
    value: "24 years old",
    description: "Single",
  },
  {
    label: "Email",
    value: "adamadha3@gmail.com",
    description: "My personal email address",
  },
  {
    label: "Experience",
    value: "Over a year",
    description: "Work experience",
  },
  {
    label: "State",
    value: "Selangor",
    description: "Living in Cyberjaya",
  },
];

export const activitiesData = [
  {
    year: "2025",
    activities: [
      {
        image: TalentMaxWinner,
        title: "PB TalentMax Winner",
        description:
          "Earned first place for delivering the most outstanding and engaging presentation",
      },
      {
        image: TalentMax,
        title: "PB TalentMax Programme",
        description:
          "Completed the PB TalentMax Programme, gaining a cross-functional view of banking operations and essential management skills.",
      },
      {
        image: CnyPBB,
        title: "CNY Celebration by Public Bank",
        description:
          "Celebrating Chinese New Year with festive activities and a group photo with my Mobile App team.",
      },
      {
        image: HackathonAm,
        title: "Ambank Hackathon",
        description: "Advanced to the final stage of the AmBank Hackathon.",
      },
    ],
  },
  {
    year: "2024",
    activities: [
      {
        image: Hadiah,
        title: "Convocation Award Recipient",
        description:
          "Awarded the Abdul Razak Hamdan Award as the best final-year student in Software Engineering.",
      },
      {
        image: Convo,
        title: "52th Convocation Ceremony",
        description:
          "Officially graduating from university, marking a significant academic milestone.",
      },
      {
        image: PBB3,
        title: "Annual Dinner of Public Bank",
        description:
          "Celebrating the 58th anniversary of Public Bank with colleagues and peers at the Annual Dinner",
      },
      {
        image: PBB2,
        title: "New Journey at Public Bank Berhad",
        description:
          "New journey as a full-time Analyst Programmer in Public Bank Berhad.",
      },
      {
        image: LastExam,
        title: "Final Exam at DECTAR, UKM",
        description:
          "Completing the last examination of the university degree at DECTAR, UKM.",
      },
      {
        image: LastPresentation,
        title: "Industrial Project Presentation",
        description:
          "Delivering the final presentation of an industrial project, marking the culmination of academic efforts.",
      },
      {
        image: CyberNight,
        title: "Cyber Night Annual Dinner",
        description:
          "Received Dean's List Award at the faculty annual dinner for outstanding performance in the final semester",
      },
      {
        image: PestaBuku,
        title: "Book Festival Invitation",
        description:
          "Being invited to the Book Festival event at UKM, highlighting literary interests and participation in the academic community.",
      },
    ],
  },
  {
    year: "2023",
    activities: [
      {
        image: PBB,
        title: "Internship at Public Bank Berhad",
        description:
          "Gaining hands-on experience in the banking sector and contributing to various projects at Public Bank Berhad.",
      },
      {
        image: Falcon,
        title: "Establishing The Falcon Amenity",
        description:
          "Creating a dedicated space for sports and gaming within the university.",
      },
      {
        image: FYP,
        title: "Final Year Project Presentation",
        description:
          "Showcasing the UKMeals project as part of the final academic requirements.",
      },
      {
        image: PKMK,
        title: "Native Student Welfare Association Role",
        description:
          "Taking on a role focused on the welfare of native students.",
      },
      {
        image: Workshop,
        title: "React Native Workshop Participation",
        description:
          "Engaging in a workshop to develop skills in React Native, a popular framework for building mobile apps.",
      },
      {
        image: ImecAGM,
        title: "IMeC Annual General Meeting Execution",
        description:
          "Organizing and executing the yearly program for the Interactive Multimedia Club.",
      },

      // {
      //   image: MajlisPertama,
      //   title: "Faculty Iftar Invitation",
      //   description:
      //     "Attending an iftar event organized by the faculty’s student representatives, celebrating community and togetherness.",
      // },
    ],
  },
  {
    year: "2022",
    activities: [
      {
        image: MajlisPenyerahan,
        title: "Handing Over Council Position",
        description:
          "Transitioning leadership by passing on responsibilities to the next student executive council.",
      },
      {
        image: Groupmate,
        title: "UKMall Project Presentation",
        description:
          "Presenting the UKMall project to the university's information technology center, along with groupmates.",
      },
      {
        image: Imec,
        title: "IMeC Executive Exco",
        description:
          "Preparing for the School@UKM program as a newly appointed executive member of IMeC.",
      },
      {
        image: SAMPENG,
        title: "SAYEMBARA UKM Representation",
        description:
          "Serving as a representative in a national-level competition held by UKM.",
      },
      {
        image: KasihBudi,
        title: "Kasih & Budi Event",
        description:
          "Serve as a Floor Manager to celebrate the contributions and efforts of the previous executive council in handling the COVID-19",
      },

      {
        image: eSportSeries,
        title: "Za'ba eSport Series Leadership",
        description:
          "Leading a national-level eSports event with participation from nearly 20 universities across Malaysia.",
      },
    ],
  },
  {
    year: "2021",
    activities: [
      {
        image: Banjir,
        title: "Flood Relief Mission",
        description:
          "Participating in efforts to assist communities affected by severe flooding.",
      },
      {
        image: ZMG,
        title: "Za'ba Mini Game Event",
        description:
          "Planning and executing a mini-game event at UKM with my exco",
      },
      {
        image: Takbir,
        title: "Takbir Raya Preparation",
        description:
          "Making arrangements for the Takbir Raya celebration, a significant cultural event.",
      },
      {
        image: MMM,
        title: "Organizing MMM",
        description:
          "Organizing and running the Minggu Mesra Mahasiswa (MMM) event to welcome new students.",
      },
      {
        image: PulauPerhentian,
        title: "Management Course Attendance",
        description:
          "Attending a management course designed to enhance organizational skills.",
      },
      {
        image: Mep,
        title: "Student Executive Council Appointment",
        description:
          "Taking on the role of Sports and Recreation Executive within the student council.",
      },
    ],
  },
  {
    year: "2020",
    activities: [
      {
        image: Classmate,
        title: "Classmate Photoshoot",
        description:
          "A memorable moment with classmates, fostering new friendships and connections.",
      },

      {
        image: PendetaStudio,
        title: "Founding The Pendeta Studio",
        description:
          "Establishing a new studio, paving the way for innovative projects and collaborations.",
      },
      {
        image: ImecFirst,
        title: "Joined the Interactive Multimedia Club (IMeC)",
        description:
          "Becoming a member of the Interactive Multimedia Club to explore creative and technological interests.",
      },
      {
        image: Vaccine,
        title: "First AstraZeneca Vaccine",
        description:
          "Receiving the initial dose of the AstraZeneca vaccine as part of the COVID-19 vaccination effort.",
      },
      {
        image: UKM,
        title: "Enrollment at UKM",
        description:
          "The beginning of a new academic journey, marked by the first day of university enrollment.",
      },
    ],
  },
];
export const portfolioData = [
  {
    title: "ChaosCreator",
    image: Website,
    category: ["Web"],
    description:
      "ChaosCreator is a portfolio website built from scratch by me using ReactJS and TailwindCSS. It serves as a comprehensive showcase of my expertise, highlighting my information, background, experience, skills, portfolio, and more.",
    b1: "Personalized Design",
    p1: "A custom-built website reflecting my unique professional journey and skill set.",
    b2: "Modern Tech Stack",
    p2: "Developed with ReactJS and TailwindCSS for optimal performance, responsiveness, and scalability.",
    b3: "Comprehensive Portfolio",
    p3: "Displays my work across web and mobile development, UI/UX design, and machine learning.",
    b4: "User-Centered Layout",
    p4: "Focuses on a clean, intuitive user experience, ensuring seamless navigation and engagement.  ",
    technology: ["ReactJS", "TailwindCSS"],
  },
  {
    title: "MyPB",
    image: MyPB,
    category: ["Mobile"],
    description:
      "MyPB is Public Bank’s flagship mobile banking app, offering a seamless and secure banking experience. I contributed to key features, including DuitNow Request for instant payments via mobile numbers. Additionally, I developed home and lockscreen widgets for both Android and iOS using Java and Swift, improving user access to essential banking features.",
    b1: "Device Management Integration",
    p1: "Implemented functionality for cross-unbinding of accounts from the previous Public Bank app, PBengage, ensuring a smooth transition to MyPB.",
    b2: "Feature Development",
    p2: "Contributed to the development of key features such as DuitNow Request and widgets to enhance user experience and enable seamless payments.",
    b3: "API Integration",
    p3: "Played a crucial role in integrating various APIs using React Native, Redux Toolkit, and NodeJS to streamline data exchange between the app and backend services.",
    b4: "Cross-Functional Collaboration",
    p4: "Worked closely with front-end and back-end teams to ensure an intuitive UI, high security, and optimal performance for the app.",

    technology: [
      "CI/CD",
      "Firebase",
      "OneSpan",
      "React Native",
      "Redux",
      "RestAPI",
      "Swagger",
      "Swift",
      "Java",
      "DuitNow",
      "Widget",
    ],
    link: [
      {
        appstore:
          "https://apps.apple.com/us/app/mypb-by-public-bank/id6446102283",
        playstore:
          "https://play.google.com/store/apps/details?id=com.pbb.mypb&hl=en",
      },
    ],
  },
  {
    title: "PBeXperience",
    image: PBeXperience,
    category: ["Mobile"],
    description:
      "PBeXperience is Public Bank's staff application developed to streamline internal operations and improve employee efficiency. A key feature I introduced is eFestive, which includes seasonal app themes for upcoming festivals, eCards for sharing festive greetings, and eBizCard for business users to easily share digital business cards.",
    b1: "Feature Development",
    p1: "Introduced eFestive, offering seasonal themes, eCards, and eBizCard functionalities to enhance the employee experience.",
    b2: "Native Widget Integration",
    p2: "Integrated widgets developed in Java and Swift, expanding the app’s functionality and improving the user experience.",
    b3: "Manual Testing & Quality Assurance",
    p3: "Conducted thorough manual testing to ensure the app's reliability and performance across various devices.",
    b4: "Intuitive Front-End Design",
    p4: "Designed user-friendly front-end interfaces that prioritized usability and aesthetic appeal, contributing to a seamless user experience.",
    technology: [
      "CI/CD",
      "Firebase",
      "OneSpan",
      "React Native",
      "Redux",
      "RestAPI",
      "Swagger",
      "Swift",
      "Java",
    ],
    link: [
      {
        appstore: "https://apps.apple.com/my/app/pbexperience/id1492984631",
        playstore:
          "https://play.google.com/store/apps/details?id=com.reacttutorialapp&hl=en",
      },
    ],
  },

  {
    title: "PB Journey",
    image: PBJourney,
    category: ["Mobile"],
    description:
      "PB Journey is a mobile application developed by Public Bank, designed to help children manage their savings in a secure and user-friendly environment. As part of the development team, I implemented the AES (Advanced Encryption Standard) cryptographic algorithm to enhance data security for sensitive transactions.",
    b1: "Encryption Integration",
    p1: "Implemented the AES cryptographic algorithm to securely encrypt data exchanged between the app and the server, ensuring the protection of sensitive financial information.",
    b2: "Backend Collaboration",
    p2: "Worked closely with the backend team to ensure smooth API integration, optimizing the app's performance while maintaining high security and reliability.",
    b3: "Security & User Privacy",
    p3: "Focused on delivering a secure app experience for children, leveraging AES encryption to prevent unauthorized access and ensure privacy for users managing their savings.",

    technology: ["React Native", "Redux", "CryptoJS"],
    link: [
      {
        appstore: "https://apps.apple.com/my/app/pb-journey/id1556803373",
        playstore:
          "https://play.google.com/store/apps/details?id=com.pbb.journey&hl=en",
      },
    ],
  },

  {
    title: "MyPB Laos",
    image: MyPBLaos,
    category: ["Mobile"],
    description:
      "MyPB Laos is a regional extension of Public Bank's mobile banking platform, tailored specifically for the Laotian market. In this project, I was involved in supporting the deployment for User Acceptance Testing (UAT), ensuring that the app was fully functional and met the operational requirements for launch in Laos.",
    b1: "UAT Deployment Support",
    p1: "Coordinated the deployment process and assisted in the User Acceptance Testing (UAT), ensuring the app met the operational and functional requirements for launch in Laos.",
    b2: "Issue Resolution & Testing",
    p2: "Provided timely resolutions for issues identified during UAT, working closely with both the development and QA teams to ensure smooth testing and feature validation.",
    b3: "Feature Verification & Collaboration",
    p3: "Collaborated with development and QA teams to verify the performance of key features, including transaction processing, account management, and security protocols, ensuring they functioned seamlessly.",

    technology: ["OneSpan", "React Native", "Redux"],
    link: [
      {
        appstore: "https://apps.apple.com/vn/app/mypbll/id6482294076?uo=2",
        playstore:
          "https://play.google.com/store/apps/details?id=com.pbb.mypblaos",
      },
    ],
  },
  {
    title: "UKMeals",
    image: FoodOrdering,
    category: ["Web"],
    description:
      "UKMeals was developed as my final year project, is an online food ordering and delivery platform specifically designed for Universiti Kebangsaan Malaysia (UKM). This comprehensive system utilizes a variety of web technologies, including HTML, JavaScript, CSS, PHP, and SQL for backend operations, alongside MySQL for robust database management.",
    b1: "Frontend Development",
    p1: "Developed a responsive user interface using Bootstrap for seamless browsing and jQuery for enhanced interactivity, ensuring an engaging and efficient user experience.",
    b2: "Backend Architecture",
    p2: "Utilized PHP and SQL for backend operations, with MySQL ensuring robust database management, and CodeIgniter to streamline backend development and maintenance.",
    b3: "System Functionality",
    p3: "Enabled UKM students and staff to browse menus, place orders, and track deliveries, enhancing campus dining with a convenient online platform.",

    technology: [
      "HTML",
      "PHP",
      "CSS",
      "JavaScript",
      "SQL",
      "CodeIgniter",
      "MySQL DB",
    ],
  },

  {
    title: "TikGram Downloader",
    image: Downloader,
    category: ["Mobile", "Machine Learning"],
    description:
      "TikGram Downloader is an application developed using Kivy, Scrapy, and Selenium, designed for downloading content from social media platforms TikTok and Instagram. Kivy provides the framework for the application's user interface, offering flexibility and responsiveness.",
    b1: "UI Development with Kivy",
    p1: "Developed the user interface using Kivy, ensuring flexibility and responsiveness for an intuitive user experience across devices.",
    b2: "Web Scraping with Scrapy",
    p2: "Used Scrapy to automate the extraction of data from TikTok and Instagram, including videos, images, and metadata, streamlining the content downloading process.",
    b3: "Automation with Selenium",
    p3: "Leveraged Selenium to automate web browser interactions, including logging into user accounts and navigating through content for efficient downloading and archiving.",
    b4: "Telegram Bot Integration",
    p4: "Currently integrating TikGram Downloader into Telegram using a bot, enabling users to download content directly through Telegram by interacting with the bot for a seamless experience.",

    technology: ["Kivy", "Python", "Scrapy", "Selenium"],
  },
  {
    title: "ZUS Coffee Clone App",
    image: CoffeeApp,
    category: ["Mobile"],
    description:
      "ZUS Coffee Clone App is a React Native application developed using Redux Toolkit, designed to replicate the intuitive user interfaces and functionalities found in popular coffee-related mobile apps. This project emphasizes front-end design and user experience, showcasing features such as browsing coffee menus, managing user preferences, and exploring interactive coffee profiles.",
    b1: "UI/UX Design",
    p1: "Focused on replicating intuitive user interfaces, ensuring seamless navigation for users to browse coffee menus, manage preferences, and explore profiles.",
    b2: "State Management",
    p2: "Utilized Redux Toolkit for efficient state management, enabling seamless data updates and consistent user experience across the app.",
    b3: "Cross-Platform Performance",
    p3: "Developed with React Native to ensure smooth performance across multiple mobile platforms, providing a consistent experience for users on both iOS and Android.",
    b4: "Customer Engagement Focus",
    p4: "Designed with user engagement in mind, aiming to create a polished, interactive experience that keeps customers engaged with coffee-related content.",

    technology: ["React Native", "Redux Toolkit"],
  },
  {
    title: "UKMall",
    image: eCommerceApp,
    category: ["Mobile"],
    description:
      "UKMall is an m-commerce application developed for UKM students, utilizing Firebase for backend services and Android Studio for Android application development. The app enables students to engage in mobile commerce activities within the UKM ecosystem, including buying and selling products, managing orders, and participating in campus-specific promotions.",
    b1: "Firebase Integration",
    p1: "Leveraged Firebase for backend services, providing real-time data synchronization, user authentication, and cloud storage to enhance app performance and reliability.",
    b2: "Native App Development",
    p2: "Developed the app using Android Studio, utilizing its robust tools for designing, debugging, and deploying Android applications tailored to the needs of UKM students.",
    b3: "Student-Centric Features",
    p3: "Designed features to enable students to buy and sell products, manage orders, and access campus-specific promotions, fostering a dynamic marketplace within the university community.",

    technology: ["Java", "XML", "Firebase"],
  },
  {
    title: "eTawaf",
    image: TrackingApp,
    category: ["Mobile"],
    description:
      "eTawaf is a Shuttle Tracking App developed using React Native and integrated with Google API, under the initiative of Jabatan Hal Ehwal Agama Islam Negeri Sembilan (JHEAINS). Designed to streamline transportation logistics within specific environments like mosques, suraus, or large facilities, this mobile application provides real-time tracking of shuttle buses, enhancing user convenience by allowing them to monitor bus locations and estimated arrival times.",
    b1: "Real-Time Shuttle Tracking",
    p1: "Developed a real-time shuttle bus tracking system, allowing users to monitor bus locations and estimated arrival times for enhanced convenience and efficiency.",
    b2: "Google API Integration",
    p2: "Integrated Google API for features like route optimization, geolocation services, and interactive map displays, ensuring accurate and reliable location data.",
    b3: "Cross-Platform Development",
    p3: "Built the app using React Native to ensure seamless performance and compatibility across both iOS and Android devices, maximizing accessibility.",
    b4: "Efficiency in Transportation",
    p4: "Aimed to improve shuttle service management and transportation logistics within mosques, suraus, and large facilities, streamlining operations and enhancing the user experience.",
    technology: ["React Native", "Google API"],
  },
  {
    title: "Mahasiswa Plaza",
    image: eCommerceWeb,
    category: ["Web"],
    description:
      "Mahasiswa Plaza represents a collaborative effort led by a team of developers and designers to conceptualize and develop a platform for a national-level competition. This project aimed to create a polished online platform tailored for student needs, achieving 75% completion of planned features. Mahasiswa Plaza secured 4th place in the national-level competition, which was organized in collaboration with PTU UTHM and UTMXCITE.",
    b1: "Team Collaboration",
    p1: "Led a collaborative effort with developers and designers to create an innovative online platform tailored to student needs, showcasing effective teamwork and communication.",
    b2: "Feature Development",
    p2: "Achieved 75% completion of planned features, including academic resources, social networking, event management, and administrative services for students.",
    b3: "Competition Recognition",
    p3: "Secured 4th place in a national-level competition, organized in collaboration with PTU UTHM and UTMXCITE, demonstrating the platform's potential and impact in the student community.",
    b4: "Project Management & Execution",
    p4: "Exhibited strong project management skills in overseeing the platform’s development, meeting deadlines, and ensuring successful execution in a competitive environment.",
    technology: ["HTML", "CSS", "WordPress"],
  },
  {
    title: "A.D.A.M AI",
    image: AI,
    category: ["Machine Learning"],
    description:
      "The Autonomous Digital Assistant Machine AI (A.D.A.M AI) is an advanced AI system designed for autonomous operation and digital assistance. Integrated with the OpenAI API and speech recognition technology, it offers seamless interaction through natural language processing and voice commands, enhancing productivity in personal, educational, and professional tasks.",
    b1: "AI-Powered Interaction",
    p1: "Integrated with OpenAI API and speech recognition technology for seamless voice and text-based user interaction, enabling efficient task management and real-time information retrieval.",
    b2: "Text-to-Speech Functionality",
    p2: "Converts text outputs into speech, allowing the assistant to read out responses, instructions, and information, making it more interactive and accessible.",
    b3: "Adaptive Learning for Personalization",
    p3: "Uses machine learning to continuously adapt and improve based on user interactions, offering a personalized and evolving assistant experience.",
    technology: ["OpenAI API", "Python"],
  },
  {
    title: "Recipe Realm",
    image: Recipe,
    category: ["Web"],
    description:
      "Recipe Realm is a web application developed using the MEAN stack (MongoDB, Express.js, Angular, Node.js), designed to provide a platform for discovering and sharing recipes. The app enables users to browse recipes, create personal collections, and interact with a community of culinary enthusiasts.",
    b1: "MEAN Development",
    p1: "Built using MongoDB, Express.js, Angular, and Node.js to provide a dynamic, responsive user experience and efficient backend API for seamless communication.",
    b2: "User-Generated Content Platform",
    p2: "Allows users to share recipes, create personalized collections, and interact with a community of culinary enthusiasts, fostering engagement and collaboration.",
    b3: "Flexible Data Management",
    p3: "Utilized MongoDB for flexible, scalable data storage, ensuring efficient recipe storage, retrieval, and management on the platform.",

    technology: ["Mongo DB", "ExpressJS", "Angular", "NodeJS"],
  },
  {
    title: "Twitter Analyzer",
    image: Analysis,
    category: ["Machine Learning"],
    description:
      "Twitter sentiment analysis uses RapidMiner to analyze sentiment from over 10,000 tweets, classifying opinions as positive, negative, or neutral. The results are visualized to provide insights into public sentiment trends, aiding market research and brand perception analysis.",
    b1: "Data Mining with RapidMiner",
    p1: "Utilized RapidMiner for scraping tweets and applying sentiment analysis algorithms to classify public opinions based on large-scale social media data.",
    b2: "Sentiment Classification",
    p2: "Classified tweets into positive, negative, or neutral categories, offering valuable insights into public sentiment trends for market research and brand analysis.",
    b3: "Data Visualization for Insights",
    p3: "Visualized sentiment analysis results to provide actionable insights, enhancing brand perception analysis and monitoring public opinions on Twitter.",

    technology: ["RapidMiner", "Twitter API"],
  },
  {
    title: "Final Year Project Poster",
    image: Poster,
    category: ["UI/UX"],
    description:
      "Created a visually engaging poster for the final year project using Figma and Adobe Illustrator.",
    technology: ["Figma", "Adobe Illustrator"],
    link: [
      {
        figma:
          "https://www.figma.com/design/1wBkqSAaJYYohB7PaC5uj1/Untitled?t=zhfO0Sj16DKqF7QI-1",
      },
    ],
  },
  {
    title: "PBenterprise",
    image: Banking,
    category: ["UI/UX"],
    description:
      "Crafted a user-friendly interface for an online banking app, emphasizing seamless navigation and an improved overall user experience.",
    technology: ["Figma"],
    link: [
      {
        figma:
          "https://www.figma.com/design/2iTsYriLf22tNxtKvXsiVk/Online-Banking-UI%2FUX?node-id=0-1&t=zhfO0Sj16DKqF7QI-1",
      },
    ],
  },
  {
    title: "m-Commerce",
    image: MobileEcommerce,
    category: ["UI/UX"],
    description:
      "Designed intuitive user interfaces for a mobile e-commerce application, focusing on enhancing user experience.",
    technology: ["Figma"],
    //link: "https://www.figma.com/design/0M9fkAj7v8HhM6riaAdt5z/Untitled?node-id=0-1&t=zhfO0Sj16DKqF7QI-1",
    link2: "To be updated",
  },
  {
    title: "e-Commerce",
    image: WebsiteEcommerce,
    category: ["UI/UX"],
    description:
      "Crafted user-friendly interfaces for an e-commerce website, emphasizing usability and aesthetic appeal.",
    technology: ["Figma"],
    link2: "To be updated",
  },
  {
    title: "Recipe Website",
    image: Cooking,
    category: ["UI/UX"],
    description:
      "Developed a clean and efficient user interface for a cooking recipe web application, ensuring ease of use and navigation.",
    technology: ["Figma"],
    link2: "To be updated",
  },
];

export const designData = [
  {
    title: "Final Year Project (FYP) Poster",
    image: Poster,
    description:
      "Created a visually engaging poster for the final year project using Figma and Adobe Illustrator.",
    technology: "Figma, Adobe Illustrator",
    link: "https://www.figma.com/design/1wBkqSAaJYYohB7PaC5uj1/Untitled?t=zhfO0Sj16DKqF7QI-1",
  },
  {
    title: "PBenterprise Online Banking UI/UX",
    image: Banking,
    description:
      "Crafted a user-friendly interface for an online banking app, emphasizing seamless navigation and an improved overall user experience.",
    technology: "Figma",
    link: "https://www.figma.com/design/2iTsYriLf22tNxtKvXsiVk/Online-Banking-UI%2FUX?node-id=0-1&t=zhfO0Sj16DKqF7QI-1",
  },
  {
    title: "Mobile e-Commerce UI/UX",
    image: MobileEcommerce,
    description:
      "Designed intuitive user interfaces for a mobile e-commerce application, focusing on enhancing user experience.",
    technology: "Figma",
    //link: "https://www.figma.com/design/0M9fkAj7v8HhM6riaAdt5z/Untitled?node-id=0-1&t=zhfO0Sj16DKqF7QI-1",
    link2: "To be updated",
  },
  {
    title: "Website e-Commerce UI/UX",
    image: WebsiteEcommerce,
    description:
      "Crafted user-friendly interfaces for an e-commerce website, emphasizing usability and aesthetic appeal.",
    technology: "Figma",
    link2: "To be updated",
  },
  {
    title: "Website Cooking Recipe UI/UX",
    image: Cooking,
    description:
      "Developed a clean and efficient user interface for a cooking recipe web application, ensuring ease of use and navigation.",
    technology: "Figma",
    link2: "To be updated",
  },
];

export const awardsData = [
  {
    title: "Royal Education Award",
    image: DeanPics,
    description: "Nominated for outstanding contributions to university",
    year: "2024",
    sub: "Nominee",
  },
  {
    title: "Abdul Razak Hamdan Award",
    image: DeanPics,
    description:
      "Honored as the Best Final Year Software Engineering Student for excellence in academics and contributions to the field",
    year: "2024",
    sub: "Honoree",
  },
  {
    title: "Dean's List Achievement Award",
    image: DeanPics,
    description:
      "Achieved Dean's List honors in 5 out of 7 semesters with a GPA above 3.67.",
    year: "2020 - 2024",
    sub: "Honoree",
  },
  {
    title: "Co-Curricular Excellence Award",
    image: CocoPics,
    description:
      "Recognized for outstanding participation and achievement in co-curricular activities.",
    year: "2023",
    sub: "Honoree",
  },
  {
    title: "National Web Development Distinction Award",
    image: Competition,
    description:
      "Secured 4th place in a national-level web development competition.",
    year: "2022",
    sub: "Finalist",
  },
  {
    title: "Student Executive Council Recognition Award",
    image: MepPics,
    description:
      "Honored for exemplary service and leadership as a member of the Student Executive Council in UKM.",
    year: "2021",
    sub: "Honoree",
  },
  {
    title: "Golden Touch Hackathon Champion",
    image: Hackathon,
    description:
      "Championed the Golden Touch Programming Hackathon, showcasing superior coding skills and innovative solutions.",
    year: "2020",
    sub: "Winner",
  },
  {
    title: "Academic Excellence Award",
    image: Academic,
    description:
      "Recognizing exceptional academic achievement for maintaining a perfect 4.00 CGPA.",
    year: "2019",
    sub: "Honoree",
  },
];

export const certsData = [
  {
    title: "Meta Front-End Developer Professional Certificate",
    image: M0,
    description: "Comprehensive training in front-end development.",
    sub: "Certificate",
    category: "Meta",
    link: "https://www.coursera.org/account/accomplishments/specialization/JWJM7PTW5R44",
  },

  {
    title:
      "Google Cloud - Introduction to Generative AI Learning Path Specialization",
    image: G0,
    description: "Specialized in the foundational aspects of generative AI.",
    sub: "Certificate",
    category: "Google Cloud",
    link: "https://www.coursera.org/account/accomplishments/specialization/EKD723IO4B2J",
  },
  {
    title: "IBM - Python for Data Science, AI & Development",
    image: I1,
    description: "Acquired Python skills for data science and AI applications.",
    sub: "Certificate",
    category: "IBM",
    link: "https://www.coursera.org/account/accomplishments/records/65L60BHV1C3G",
  },
  {
    title: "IBM - Test and Behavior Driven Development",
    image: I2,
    description: "Learned TDD and BDD methodologies for software testing.",
    sub: "Certificate",
    category: "IBM",
    link: "https://www.coursera.org/account/accomplishments/records/0308EW4YSL0L",
  },
  {
    title: "Meta - Introduction to Front-End Development",
    image: M1,
    description: "Foundational skills in front-end web development.",
    sub: "Certificate",
    category: "Meta",
    link: "https://www.coursera.org/account/accomplishments/records/U9OACFJBKT9D",
  },
  {
    title: "Meta - Programming with JavaScript",
    image: M2,
    description: "Mastered JavaScript for building dynamic web applications.",
    sub: "Certificate",
    category: "Meta",
    link: "https://www.coursera.org/account/accomplishments/records/A3ESNTI9CGKG",
  },
  {
    title: "Meta - Version Control",
    image: M3,
    description: "Mastered Git for version control and collaboration.",
    sub: "Certificate",
    category: "Meta",
    link: "https://www.coursera.org/account/accomplishments/records/QNM0TTEDSMDT",
  },
  {
    title: "Meta - HTML & CSS in Depth",
    image: M4,
    description: "Learned advanced HTML and CSS for responsive web design.",
    sub: "Certificate",
    category: "Meta",
    link: "https://www.coursera.org/account/accomplishments/records/CHOX9FAP5OUJ",
  },
  {
    title: "Meta - React Basics",
    image: M5,
    description:
      "Learned the fundamentals of React for building web applications.",
    sub: "Certificate",
    category: "Meta",
    link: "https://www.coursera.org/account/accomplishments/records/GJJB8X8022WD",
  },
  {
    title: "Meta - Advanced React",
    image: M6,
    description: "Advanced concepts and practices in React development.",
    sub: "Certificate",
    category: "Meta",
    link: "https://www.coursera.org/account/accomplishments/records/AFZ16N3RAHEJ",
  },
  {
    title: "Meta - Principle of UX/UI Design",
    image: M7,
    description:
      "Studied core UX/UI design principles for creating user-friendly interfaces.",
    sub: "Certificate",
    category: "Meta",
    link: "https://www.coursera.org/account/accomplishments/records/3R7PN1J58U0W",
  },
  {
    title: "Meta - Front-End Developer Capstone",
    image: M8,
    description: "Completed a capstone project showcasing front-end skills.",
    sub: "Certificate",
    category: "Meta",
    link: "https://www.coursera.org/account/accomplishments/records/I83V5CNOYTRE",
  },
  {
    title: "Meta - Coding Interview Preparation",
    image: M9,
    description: "Prepared for technical interviews with coding challenges.",
    sub: "Certificate",
    category: "Meta",
    link: "https://www.coursera.org/account/accomplishments/records/XWKDCBEPPOGO",
  },

  {
    title: "Google Cloud - Introduction to Generative AI",
    image: G1,
    description: "Explored the basics of generative AI models.",
    sub: "Certificate",
    category: "Google Cloud",
    link: "https://www.coursera.org/account/accomplishments/records/OFE04EQD9QWZ",
  },
  {
    title: "Google Cloud - Introduction to Large Language Models",
    image: G2,
    description: "Studied large language models and their applications.",
    sub: "Certificate",
    category: "Google Cloud",
    link: "https://www.coursera.org/account/accomplishments/records/89MCSIT06WLG",
  },
  {
    title: "Google Cloud - Introduction to Responsible AI",
    image: G3,
    description: "Learned about ethical AI practices.",
    sub: "Certificate",
    category: "Google Cloud",
    link: "https://www.coursera.org/account/accomplishments/records/5D4NTG8S03QL",
  },
  {
    title: "Google Cloud - Responsible AI: Applying AI Principles",
    image: G4,
    description: "Applied AI principles to ensure responsible AI development.",
    sub: "Certificate",
    category: "Google Cloud",
    link: "https://www.coursera.org/account/accomplishments/records/8DSLY5KC3E6B",
  },

  // {
  //   title: "Asian Banking School - Ethics in Banking",
  //   image: A1,
  //   description:
  //     "Studied ethical practices and regulatory compliance within the banking industry.",
  //   sub: "Training",
  // },
  {
    title: "Bizagi - Process Modelling",
    image: B1,
    description:
      "Gained expertise in creating and managing process models using Bizagi.",
    sub: "Training",
    category: "Bizagi",
  },
  {
    title: "Bizagi - Essentials of Process Automation",
    image: B2,
    description:
      "Learned the fundamentals of automating business processes with Bizagi.",
    sub: "Training",
    category: "Bizagi",
  },
  {
    title: "Bizagi - Process Automation 1",
    image: B3,
    description:
      "Advanced training in implementing process automation solutions with Bizagi.",
    sub: "Training",
    category: "Bizagi",
  },
  {
    title: "Bizagi - Process Automation 2",
    image: B4,
    description:
      "Further advanced techniques in process automation and optimization using Bizagi.",
    sub: "Training",
    category: "Bizagi",
  },
];

export const educationData = [
  {
    title: "Bachelor of Software Engineering (Information System Development)",
    subtitle: "Universiti Kebangsaan Malaysia",
    cgpa: "CGPA: 3.71",
    year: "October 2020 - August 2024",
    description:
      "Studied Software Engineering with a focus on Information System Development at Universiti Kebangsaan Malaysia as a JPA scholarship holder. Completed the program from October 2020 to August 2024, gaining comprehensive knowledge in software design, development methodologies, and project management.",
  },
  {
    title: "Physical Science Stream (Computer Science)",
    cgpa: "CGPA: 4.00",
    subtitle: "Kolej Matrikulasi Pulau Pinang",
    year: "March 2019 - March 2020",
    description:
      "Completed the Physical Science Stream with a specialization in Computer Science at Kolej Matrikulasi Pulau Pinang from March 2019 to March 2020. Developed skills in programming, algorithms, and problem-solving, laying a strong foundation for further studies and career in technology.",
  },
  {
    title: "Sijil Pelajaran Malaysia",
    subtitle: "Penang Free School",
    year: "January 2014 - December 2018",
    description:
      "Completed secondary education at Penang Free School from January 2014 to December 2018, achieving solid academic results and participating actively in extracurricular activities. Developed a strong academic foundation and interpersonal skills during this period.",
  },
];

export const experienceData = [
  {
    title: "Public Bank Berhad (IT Strategy & Architecture)",
    subtitle: "Analyst Programmer",
    year: "May 2024 - Current",
    description:
      "Currently employed as an Analyst Programmer in the Information Technology Department at Public Bank Berhad. Responsibilities include developing mobile applications using React Native, Redux Toolkit, Java, Swift and RESTful API for the MyPB, PBeXperience, PB Journey and PB Laos apps, conducting thorough manual testing and API testing with Postman, and designing UI/UX for the upcoming revamp of the PBenterprise app using Figma. Actively contribute to cross-functional teams, ensuring high-quality software delivery as a Scrum Master while continuously advancing technical skills.",
  },
  {
    title: "Public Bank Berhad ( Fintech & Digitalisation)",
    subtitle: "Intern Programmer",
    year: "September 2023 - February 2024",
    description:
      "Developed a prototype and demo app for PBB's upcoming mobile application using React Native and Redux Toolkit. Implemented key features in the PBeXperience app, enhancing user experience and functionality, and received positive feedback from users and stakeholders. Integrated advanced SEO strategies for better app visibility and employed Firebase, Crashlytics, and Google Analytics for comprehensive tracking of crashes, bugs, and errors, ensuring optimal performance and user satisfaction.",
  },
];

export const hobbiesData = [
  { name: "Badminton", image: Badminton },
  { name: "Snooker", image: Snooker },
  { name: "Bowling", image: Bowling },
  { name: "eSport", image: eSport },
  { name: "Fit Touch", image: FitTouch },
  { name: "Hiking", image: Hiking },
  { name: "Snorkeling", image: Snorkeling },
  { name: "Squash", image: Squash },
];

export const skillsData = [
  {
    category: "Programming Language",
    icon: boxiconsLogos.Codepen,
    list: [
      { name: "C++", percentage: 60, icon: boxiconsLogos.CPlusPlus },
      { name: "C#", percentage: 55, icon: simpleIcons.Csharp },
      { name: "CSS", percentage: 70, icon: boxiconsLogos.Css3 },
      { name: "HTML", percentage: 65, icon: boxiconsLogos.Html5 },
      { name: "Java", percentage: 65, icon: boxiconsLogos.Java },
      { name: "JavaScript", percentage: 70, icon: boxiconsLogos.Javascript },
      { name: "PHP", percentage: 60, icon: boxiconsLogos.Php },
      { name: "Python", percentage: 70, icon: boxiconsLogos.Python },
      { name: "SQL", percentage: 65, icon: simpleIcons.Mysql },
      { name: "Swift", percentage: 60, icon: simpleIcons.Swift },
      { name: "TypeScript", percentage: 65, icon: boxiconsLogos.Typescript },
      { name: "VB.NET", percentage: 55, icon: simpleIcons.Dotnet },
      { name: "XML", percentage: 55, icon: bootstrap.FiletypeXml },
    ],
  },
  {
    category: "Framework",
    icon: boxiconsLogos.Angular,
    list: [
      { name: "Angular", percentage: 55, icon: boxiconsLogos.Angular },
      { name: "Bootstrap", percentage: 70, icon: boxiconsLogos.Bootstrap },
      { name: "CodeIgniter", percentage: 60, icon: simpleIcons.Codeigniter },
      { name: "ExpressJS", percentage: 50, icon: simpleIcons.Express },
      { name: "jQuery", percentage: 65, icon: boxiconsLogos.Jquery },
      { name: "Kivy", percentage: 60, icon: boxiconsLogos.Python },
      { name: "NodeJS", percentage: 60, icon: boxiconsLogos.Nodejs },
      { name: "ReactJS", percentage: 70, icon: boxiconsLogos.ReactLogo },
      { name: "React Native", percentage: 70, icon: boxiconsLogos.ReactLogo },
      { name: "Redux", percentage: 65, icon: boxiconsLogos.Redux },
      { name: "TailwindCSS", percentage: 70, icon: boxiconsLogos.TailwindCss },
    ],
  },
  {
    category: "Machine Learning",
    icon: bootstrap.Robot,
    list: [
      { name: "Beautiful Soup", percentage: 70, icon: simpleIcons.Python },
      { name: "NumPy", percentage: 65, icon: simpleIcons.Numpy },
      { name: "Pandas", percentage: 70, icon: simpleIcons.Pandas },
      { name: "Power BI", percentage: 70, icon: simpleIcons.Powerbi },
      { name: "PyTorch", percentage: 65, icon: simpleIcons.Pytorch },
      { name: "RapidMiner", percentage: 65, icon: simpleIcons.Databricks },
      { name: "Scrapy", percentage: 70, icon: simpleIcons.Scratch },
      { name: "Selenium", percentage: 65, icon: simpleIcons.Selenium },
    ],
  },
  {
    category: "Database & API",
    icon: simpleIcons.Datacamp,
    list: [
      { name: "Firebase", percentage: 70, icon: boxiconsLogos.Firebase },
      { name: "MongoDB", percentage: 60, icon: boxiconsLogos.Mongodb },
      { name: "MySQL", percentage: 70, icon: simpleIcons.Mysql },
      { name: "SQLite", percentage: 60, icon: simpleIcons.Sqlite },
      { name: "REST API", percentage: 65, icon: simpleIcons.Razer },
      { name: "OpenAI API", percentage: 55, icon: simpleIcons.Openai },
      { name: "Google API", percentage: 55, icon: simpleIcons.Googlemaps },
      { name: "Postman API", percentage: 65, icon: simpleIcons.Postman },
      { name: "Stripe API", percentage: 60, icon: simpleIcons.Stripe },
      { name: "Twitter API", percentage: 55, icon: simpleIcons.Twitter },
    ],
  },

  // {
  //   category: "API",
  //   icon: simpleIcons.Sqlite,
  //   list: [
  //     { name: "Google Maps", percentage: 80, icon: simpleIcons.Googlemaps },
  //     { name: "Postman", percentage: 70, icon: simpleIcons.Postman },
  //     { name: "Stripe", percentage: 70, icon: simpleIcons.Stripe },
  //     { name: "Twitter", percentage: 75, icon: simpleIcons.Twitter },
  //   ],
  // },

  {
    category: "Game & Design",
    icon: simpleIcons.Nintendogamecube,
    list: [
      { name: "Blender", percentage: 65, icon: simpleIcons.Blender },
      { name: "Unity", percentage: 65, icon: simpleIcons.Unity },
      { name: "Unreal Engine", percentage: 60, icon: simpleIcons.Unrealengine },
      { name: "Figma", percentage: 75, icon: simpleIcons.Figma },
      { name: "Sketch", percentage: 65, icon: simpleIcons.Sketch },
      {
        name: "Illustrator",
        percentage: 70,
        icon: simpleIcons.Adobeillustrator,
      },
      { name: "Photoshop", percentage: 70, icon: simpleIcons.Adobephotoshop },
      {
        name: "Premiere Pro",
        percentage: 60,
        icon: simpleIcons.Adobepremierepro,
      },
      {
        name: "XD",
        percentage: 75,
        icon: simpleIcons.Adobeindesign,
      },
    ],
  },
  {
    category: "Networking & Others",
    icon: simpleIcons.Hackaday,
    list: [
      { name: "Bash", percentage: 50, icon: simpleIcons.Gnubash },
      { name: "Linux", percentage: 50, icon: simpleIcons.Linux },
      { name: "PowerShell", percentage: 50, icon: simpleIcons.Powershell },
      { name: "Ubuntu", percentage: 50, icon: simpleIcons.Ubuntu },
      { name: "VirtualBox", percentage: 50, icon: simpleIcons.Virtualbox },
      {
        name: "Azure Devops",
        percentage: 60,
        icon: simpleIcons.Azuredevops,
      },
      { name: "CI/CD", percentage: 60, icon: simpleIcons.Infiniti },

      { name: "GitHub", percentage: 70, icon: simpleIcons.Github },
      {
        name: "GitLab",
        percentage: 65,
        icon: simpleIcons.Gitlab,
      },
      { name: "Jira", percentage: 70, icon: simpleIcons.Jira },
    ],
  },
  // {
  //   category: "Multimedia Tool",
  //   icon: simpleIcons.Mediafire,
  //   list: [
  //     { name: "Figma", percentage: 90, icon: simpleIcons.Figma },
  //     {
  //       name: "Illustrator",
  //       percentage: 80,
  //       icon: simpleIcons.Adobeillustrator,
  //     },
  //     { name: "Photoshop", percentage: 85, icon: simpleIcons.Adobephotoshop },
  //     {
  //       name: "Premiere Pro",
  //       percentage: 75,
  //       icon: simpleIcons.Adobepremierepro,
  //     },
  //     {
  //       name: "Experience Design",
  //       percentage: 80,
  //       icon: simpleIcons.Adobeindesign,
  //     },
  //   ],
  // },
  // {
  //   category: "Project Management",
  //   icon: simpleIcons.Mediafire,
  //   list: [
  //     {
  //       name: "Azure Devops",
  //       percentage: 75,
  //       icon: simpleIcons.Azuredevops,
  //     },
  //     { name: "CI/CD", percentage: 70, icon: simpleIcons.Infiniti },

  //     { name: "GitHub", percentage: 80, icon: simpleIcons.Github },
  //     {
  //       name: "GitLab",
  //       percentage: 75,
  //       icon: simpleIcons.Gitlab,
  //     },
  //     { name: "Jira", percentage: 75, icon: simpleIcons.Jira },
  //   ],
  // },
];

export const interestsData = [
  "Data Engineering",
  "Data Scientist",
  "Quality Assurance",
  "Software Tester",
  "Web Developer",
  "Mobile Developer",
  "UI/UX Designer",
  "Graphic Designer",
];

export const servicesData = [
  {
    title: "UI/UX Designing",
    description: "Design intuitive and user-friendly interfaces.",
    icon: <FontAwesomeIcon icon={faMobilePhone} style={{ color: "white" }} />,
    priceRange: "RM 35 - RM 100",
    software: "Figma, Adobe XD",
  },
  {
    title: "Poster Designing",
    description: "Create eye-catching posters for various needs.",
    icon: <FontAwesomeIcon icon={faImage} style={{ color: "white" }} />,
    priceRange: "RM 20 - RM 100",
    software: "Adobe Illustrator, Canva",
  },
  {
    title: "Photo Editing",
    description: "Enhance and edit photos to perfection.",
    icon: <FontAwesomeIcon icon={faCamera} style={{ color: "white" }} />,
    priceRange: "RM 20 - RM 100",
    software: "Adobe Photoshop, Lightroom",
  },
  {
    title: "Web Design",
    description: "Build front-end modern and responsive websites.",
    icon: <FontAwesomeIcon icon={faLaptop} style={{ color: "white" }} />,
    priceRange: "RM 100 - RM 300",
    software: "HTML, CSS, JavaScript",
  },
];

export const testimonialsData = [
  {
    name: "Ahmad Siddiq",
    review:
      "The UI/UX design service exceeded my expectations. The designs were both intuitive and user-friendly, significantly improving the overall user experience. I highly endorse his services for anyone aiming to enhance his digital platform.",
    company: "Touch 'n Go",
  },
  {
    name: "Amira Najiha",
    review:
      "I was extremely impressed with the poster design service. The posters were vibrant and perfectly met my needs. This service has revolutionized my marketing efforts, and I highly recommend it to anyone seeking impactful visual designs.",
    company: "Petronas",
  },
  {
    name: "JujuSoyaa",
    review:
      "The photo editing service was outstanding. The photos were refined to perfection, making my products look exceptional. The results have significantly boosted my marketing campaigns, and I am thoroughly satisfied with the service.",
    company: "Youtuber",
  },
];

export const companyData = [
  {
    name: "Affin Bank",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Alliance Bank",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Ambank",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "BSN",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Bank Islam",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Bank Rakyat",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Bank Negara",
    industry: "Banking & Financial Services",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "CIMB",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Citibank",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "GXBank",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Hong Leong Bank",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "HSBC",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "MBSB",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Maybank",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "OCBC",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "International",
  },

  {
    name: "Public Bank",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "RBC",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "Standard Chartered",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "PNB",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "KWAP",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "JP Morgan",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "Finexus",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "Mastercard",
    industry: "Banking & Financial Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "Microsoft",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Intel",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Western Digital",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Accenture",
    industry: "Professional Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "Razer",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Acer",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Asus",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Huawei",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Dell",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Apple",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Samsung",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "TikTok",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "SLB",
    industry: "Technology",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "KPMG",
    industry: "Professional Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "TCS",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "MCMC",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },

  {
    name: "Sony",
    industry: "Entertainment & Media",
    type: "Private",
    origin: "Japan",
  },
  {
    name: "Google",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Infineon",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },

  {
    name: "Maxis",
    industry: "Telecommunications",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "CelcomDigi",
    industry: "Telecommunications",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Umobile",
    industry: "Telecommunications",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Astro",
    industry: "Entertainment & Media",
    type: "Private",
    origin: "Malaysia",
  },

  {
    name: "Grab",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Shopee",
    industry: "E-commerce",
    type: "Private",
    origin: "International",
  },
  {
    name: "FoodPanda",
    industry: "E-commerce",
    type: "Private",
    origin: "International",
  },
  {
    name: "Lazada",
    industry: "E-commerce",
    type: "Private",
    origin: "International",
  },
  {
    name: "Zalora",
    industry: "E-commerce",
    type: "Private",
    origin: "International",
  },
  {
    name: "Boost",
    industry: "E-commerce",
    type: "Private",
    origin: "International",
  },
  {
    name: "TnG",
    industry: "E-commerce",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "DHL",
    industry: "Logistics & Transportation",
    type: "Private",
    origin: "International",
  },
  {
    name: "Nestle",
    industry: "Food & Beverage",
    type: "Private",
    origin: "International",
  },
  {
    name: "Uniqlo",
    industry: "Retail",
    type: "Private",
    origin: "International",
  },
  {
    name: "Unilever",
    industry: "Consumer Goods",
    type: "Private",
    origin: "International",
  },
  {
    name: "Swift",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "SOCAR",
    industry: "Car Sharing",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Tesla",
    industry: "Automotive",
    type: "Private",
    origin: "International",
  },
  {
    name: "EA",
    industry: "Entertainment",
    type: "Private",
    origin: "International",
  },
  {
    name: "Xsolla",
    industry: "Gaming",
    type: "Private",
    origin: "International",
  },
  {
    name: "Playstation",
    industry: "Gaming",
    type: "Private",
    origin: "Japan",
  },
  {
    name: "MDEC",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },

  {
    name: "Khazanah Nasional",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "Top Glove",
    industry: "Healthcare",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "YTL",
    industry: "Infrastructure",
    type: "Private",
    origin: "Malaysia",
  },

  {
    name: "IOI",
    industry: "Plantation",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Sunway",
    industry: "Property Development",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Berjaya",
    industry: "Retail & Hospitality",
    type: "Private",
    origin: "Malaysia",
  },

  {
    name: "Petron",
    industry: "Oil & Gas",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Shell",
    industry: "Oil & Gas",
    type: "Private",
    origin: "International",
  },
  {
    name: "Exxonmobil",
    industry: "Oil & Gas",
    type: "Private",
    origin: "International",
  },
  {
    name: "Hibiscus",
    industry: "Oil & Gas",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "FrieslandCampina",
    industry: "Dairy",
    type: "Private",
    origin: "International",
  },
  {
    name: "PWC",
    industry: "Professional Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "Hp",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "IBM",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Oracle",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Cisco",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Deloitte",
    industry: "Professional Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "Capgemini",
    industry: "Professional Services",
    type: "Private",
    origin: "International",
  },
  {
    name: "DXC",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "HCL",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "NTT",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Hitachi",
    industry: "Technology",
    type: "Private",
    origin: "Japan",
  },
  {
    name: "Fujitsu",
    industry: "Technology",
    type: "Private",
    origin: "Japan",
  },
  {
    name: "LG",
    industry: "Technology",
    type: "Private",
    origin: "South Korea",
  },
  {
    name: "Facebook/Meta",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Airbnb",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "AliBaba",
    industry: "Technology",
    type: "Private",
    origin: "China",
  },
  {
    name: "AWS",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Canon",
    industry: "Technology",
    type: "Private",
    origin: "Japan",
  },
  {
    name: "Panasonic",
    industry: "Technology",
    type: "Private",
    origin: "Japan",
  },
  {
    name: "Sharp",
    industry: "Technology",
    type: "Private",
    origin: "Japan",
  },
  {
    name: "Seagate",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "NVIDIA",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "AMD",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "Qualcomm",
    industry: "Technology",
    type: "Private",
    origin: "International",
  },
  {
    name: "TM",
    industry: "Telecommunications",
    type: "GLC",
    origin: "Malaysia",
  },

  {
    name: "MyDIGITAL",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "MAVCAP",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "MTDC",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "MaGIC",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "SIRIM",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "MARA",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "JCORP",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },

  {
    name: "DNB",
    industry: "Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },

  {
    name: "BERNAMA",
    industry: "Media",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "Malaysia Airlines",
    industry: "Airlines",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "FELDA",
    industry: "Government",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "Sime Darby",
    industry: "Conglomerate",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "PLUS",
    industry: "Infrastructure",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Prasarana",
    industry: "Infrastructure",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "MAHB",
    industry: "Infrastructure",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "MIMOS",
    industry: "Technology",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "TNB",
    industry: "Utilities",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "DRB-HICOM",
    industry: "Conglomerate",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Bank Rakyat",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },

  {
    name: "BPMB",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },

  {
    name: "Paynet",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "EPF",
    industry: "Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Tabung Haji",
    industry: "Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "PR1MA",
    industry: "Property Development",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "PPj",
    industry: "Local Government",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "Petronas",
    industry: "Oil & Gas",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "Axiata",
    industry: "Telecommunications",
    type: "GLC",
    origin: "Malaysia",
  },

  {
    name: "iHH",
    industry: "Healthcare",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "RTM",
    industry: "Media",
    type: "Government",
    origin: "Malaysia",
  },
  {
    name: "Affin",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Agrobank",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "AmanahRaya",
    industry: "Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Bursa",
    industry: "Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "FELCRA",
    industry: "Agriculture",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "FGV",
    industry: "Plantation",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "IndahWater",
    industry: "Utilities",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "KLCC Property",
    industry: "Property Development",
    type: "GLC",
    origin: "Malaysia",
  },

  {
    name: "MRCB",
    industry: "Property Development",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "MyHSR",
    industry: "Transportation",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Perodua",
    industry: "Automotive",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Proton",
    industry: "Automotive",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Pharmaniaga",
    industry: "Healthcare",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "RHB",
    industry: "Banking & Financial Services",
    type: "GLC",
    origin: "Malaysia",
  },

  {
    name: "TimeDotCom",
    industry: "Telecommunications",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "UEM",
    industry: "Infrastructure",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "GAMUDA",
    industry: "Infrastructure",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "EON",
    industry: "Automotive",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Modenas",
    industry: "Automotive",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Naza",
    industry: "Automotive",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Takaful",
    industry: "Insurance",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Honda",
    industry: "Automotive",
    type: "Private",
    origin: "Japan",
  },
  {
    name: "SAPURA",
    industry: "Oil & Gas",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "AirAsia",
    industry: "Airlines",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "Malakoff",
    industry: "Utilities",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Rapid KL",
    industry: "Transportation",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Media Prima",
    industry: "Media",
    type: "GLC",
    origin: "Malaysia",
  },
  {
    name: "Isuzu",
    industry: "Automotive",
    type: "Private",
    origin: "Japan",
  },
  {
    name: "Suzuki",
    industry: "Automotive",
    type: "Private",
    origin: "Japan",
  },
  {
    name: "Avon",
    industry: "Beauty & Personal Care",
    type: "Private",
    origin: "International",
  },
  {
    name: "Ford",
    industry: "Automotive",
    type: "Private",
    origin: "International",
  },
  {
    name: "Hyundai",
    industry: "Automotive",
    type: "Private",
    origin: "South Korea",
  },
  {
    name: "KPJ",
    industry: "Healthcare",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "KFC",
    industry: "Food & Beverage",
    type: "Private",
    origin: "International",
  },
  {
    name: "Pizza Hut",
    industry: "Food & Beverage",
    type: "Private",
    origin: "International",
  },
  {
    name: "A&W",
    industry: "Food & Beverage",
    type: "Private",
    origin: "International",
  },
  {
    name: "Utusan",
    industry: "Media",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "OSK",
    industry: "Financial Services",
    type: "Private",
    origin: "Malaysia",
  },
  {
    name: "MISC",
    industry: "Shipping",
    type: "GLC",
    origin: "Malaysia",
  },
];
